import HeadingSection from "../HeadingSection";
import row1Image from "../../assets/Overview/image1.png";
import row2Image from "../../assets/Overview/image2.png";

import "./styles.scss";

const Overview = () => {
  return (
    <div className="overview" id="overview">
      <div className="inner">
        <HeadingSection text="Overview" />
        <div className="rows">
          <div className="row1">
            <div>
              <img
                className="overview-image"
                src={row1Image}
                alt="Price growth and value"
              />
            </div>

            <div className="text-container">
              <h2>Price Growth and Value</h2>
              <p className="text">
                SLAPA X coin price growth and value are created not only from
                the assets, products, services and platforms, rich ecosystem of
                its parent Ultainfinity Global Group but also from its intrinsic
                backing by SLAPA X through its novel S L A P A Model & Systems;
                a composite model with multiple unique subsystems and
                multitudinous use cases.
              </p>
            </div>
          </div>
          <div className="row2">
            <div>
              <img
                className="overview-image"
                src={row2Image}
                alt="Price growth and value"
              />
            </div>

            <div className="text-container">
              <h2>Price Derivation</h2>
              <p className="text">
                Furthermore, SLAPA X coin’s superior, stable and sustained price
                growth is derived, not only from the intrinsic value created by
                the technical implementation of the Model but also from the
                careful, predictable and predetermined effects of market forces
                it compellingly conjures. The price growth of SLAPA X coin is
                able to be forecasted and foreseen because it’s impregnable,
                even in downtrends and market dislocations or black swan events,
                due to the supportive underpinnings of SLAPA X. Thus, an
                inexorable, incentivised and inherent value chain is created
                because of people’s willingness to hold SLAPA X coin
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
