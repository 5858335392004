import icon1 from "../../assets/Features/icon1.svg";
import icon2 from "../../assets/Features/icon2.svg";
import icon3 from "../../assets/Features/icon3.svg";
import "./styles.scss";
import HeadingSection from "../HeadingSection/index";
import Feature from "./Feature";

const features = [
  {
    name: "Strong Security",
    text: "Secure transaction and record information with 2-factor authentication (2FA)",
    icon: icon1
  },
  {
    name: "Payment Options",
    text: "Flexible payment of methods with Visa, Mastercard, Paypal, bank transfer",
    icon: icon2
  },
  {
    name: "Market Updated",
    text: "The most accurate and trusted source for market cap, valuation, and cryptocurrency information",
    icon: icon3
  }
];

const Features = () => {
  return (
    <div className="features" id="features">
      <HeadingSection text="Features" />
      <div className="main">
        <div className="feature-list">
          {features.map((feature, i) => (
            <Feature {...feature} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
