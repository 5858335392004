import "./styles.scss";

const Hero = () => {
  return (
    <div className="hero-container">
      <p className="hero-text">Powered by SLAPA Model & Systems</p>
      <h1 className="hero-title">Swap and Speculate, Succesfully</h1>
      <div className="button-container">
        <div className="button">Login</div>
        <div className="button primary">Sign Up</div>
      </div>
    </div>
  );
};

export default Hero;
