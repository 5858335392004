import icon from "../../../assets/FAQ/icon.svg";
import right from "../../../assets/FAQ/right.svg";
import { useState } from "react";

const faqs1 = [
  {
    question: "What is SLAPA X coin",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit.  Molestias, nisi. Omnis, in itaque quis aliquid sint debitis temporibus aut tempore mollitia aliquam nesciunt. Praesentium, fugiat tenetur dolorum provident incidunt nesciunt"
  },
  {
    question: "How does SLAPA X coin work",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis laudantium commodi necessitatibus deserunt ut rerum temporibus doloremque odit, sunt magnam ipsa consequuntur, reiciendis fugiat error suscipit, animi quasi illum voluptatibus!"
  },
  {
    question: "Why is SLAPA X coin special",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis laudantium commodi necessitatibus deserunt ut rerum temporibus doloremque odit, sunt magnam ipsa consequuntur, reiciendis fugiat error suscipit, animi quasi illum voluptatibus!"
  },
  {
    question: "Why should i trade SLAPA X coin",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis laudantium commodi necessitatibus deserunt ut rerum temporibus doloremque odit, sunt magnam ipsa consequuntur, reiciendis fugiat error suscipit, animi quasi illum voluptatibus!"
  }
];

const Faq1 = () => {
  const [selected, setSelected] = useState<any>(null);

  const toggleShow = (i: number) => {
    console.log("clicked");
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <div className="section">
      {faqs1.map(({ question, answer }, i) => {
        return (
          <div className={`question ${selected === i ? "active" : ""}`} key={i}>
            <div
              className="title"
              onClick={() => {
                toggleShow(i);
              }}
            >
              <div className="title-text">
                <img src={icon} alt="question" />
                <span>{question}</span>
              </div>
              <img className="drop" src={right} alt="rigth" />
            </div>
            <p>{answer}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Faq1;
