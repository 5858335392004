import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero/index";
import About from "./components/About/index";
import Overview from "./components/Overview";
import Features from "./components/Features";
import HowItWorks from "./components/HowItWorks";
import MobileApp from "./components/MobileApp";
import WaitingList from "./components/WaitingList";
import Faqs from "./components/Faqs";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      <About />
      <Overview />
      <Features />
      <HowItWorks />
      <MobileApp />
      <WaitingList />
      <Faqs />
      <Footer />
    </div>
  );
}

export default App;
