import HeadingSection from "../HeadingSection";
import "./styles.scss";

const About = () => {
  return (
    <div className="about" id="about-us">
      <HeadingSection text="About"/>
      <p className="about-text">
        Demand for SLAP Xcoin across the global ecosystem is Limitless, as its
        use case increases, more so as it is the initial reference
        cryptocurrency for daily trading on syntheticslapa, with predicted
        exponential daily volume and growth. Support for divit is strong,
        stable, embedded and empowered by SLAPA Model & Systems. SLAPA X Coin is
        part of Ultainfinity Global Group, a conglomeration of diverse and
        fast-growing financial and technology companies based in the UK, USA,
        and European Union. We are launching the cryptocurrencies of the future
        and the most significant wealth-generating platforms for generations to
        come.
      </p>

      <p className="about-text follow">
        Follow us on over 300 channels of our various brands across different
        global social media handles for more updates.
      </p>
    </div>
  );
};

export default About;
