const FooterLinkIcon = ({ linkIcon }: any) => {
  const { name, icon, link } = linkIcon;

  return (
    <a key={Math.random()} href={link}>
      <img
        src={icon}
        title={name}
        alt={name}
        className="footer-container__socials__set__icon"
      />
    </a>
  );
};

export default FooterLinkIcon;
