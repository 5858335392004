interface feature {
  name: string;
  text: string;
  icon: string;
}

const Feature = ({ name, text, icon }: feature) => {
  return (
    <div className="feature">
      <img src={icon} alt={name} className="icon" />
      <h2 className="title">{name}</h2>
      <p className="text">{text}</p>
    </div>
  );
};

export default Feature;
