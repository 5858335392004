import facebookIcon from "../../assets/Footer/facebook.svg";
import instagramIcon from "../../assets/Footer/instagram.svg";
import youtubeIcon from "../../assets/Footer/youtube.svg";
import twitterIcon from "../../assets/Footer/twitter.svg";
import pinterestIcon from "../../assets/Footer/pinterest.svg";
import linkedinIcon from "../../assets/Footer/linkedin.svg";
import discordIcon from "../../assets/Footer/discord.svg";
import tiktokIcon from "../../assets/Footer/tiktok.svg";
import redditIcon from "../../assets/Footer/reddit.svg";
import telegramIcon from "../../assets/Footer/telegram.svg";
import whatsappIcon from "../../assets/Footer/whatsapp.svg";
import messengerIcon from "../../assets/Footer/messenger.svg";
import viberIcon from "../../assets/Footer/viber.svg";
import signalIcon from "../../assets/Footer/signal.svg";
import snapchatIcon from "../../assets/Footer/snapchat.svg";
import lineIcon1 from "../../assets/Footer/line.svg";
import quoraIcon from "../../assets/Footer/quora.svg";
import mediumIcon from "../../assets/Footer/medium.svg";

type linkIcon = { name: string; icon: string; link: string }[];

const linkIconDetails: linkIcon = [
  {
    name: "Facebook",
    icon: facebookIcon,
    link: "#"
  },
  {
    name: "Instagram",
    icon: instagramIcon,
    link: "#"
  },
  {
    name: "Youtube",
    icon: youtubeIcon,
    link: "#"
  },
  {
    name: "Twitter",
    icon: twitterIcon,
    link: "#"
  },
  {
    name: "Pinterest",
    icon: pinterestIcon,
    link: "#"
  },
  {
    name: "Link In",
    icon: linkedinIcon,
    link: "#"
  },
  {
    name: "Discord",
    icon: discordIcon,
    link: "#"
  },
  {
    name: "Tiktok",
    icon: tiktokIcon,
    link: "#"
  },
  {
    name: "Reddit",
    icon: redditIcon,
    link: "#"
  },
  {
    name: "Telegram",
    icon: telegramIcon,
    link: "#"
  },
  {
    name: "Whatsapp",
    icon: whatsappIcon,
    link: "#"
  },
  {
    name: "Messenger",
    icon: messengerIcon,
    link: "#"
  },
  {
    name: "Viber",
    icon: viberIcon,
    link: "#"
  },
  {
    name: "Signal",
    icon: signalIcon,
    link: "#"
  },
  {
    name: "Snapchat",
    icon: snapchatIcon,
    link: "#"
  },
  {
    name: "Line1",
    icon: lineIcon1,
    link: "#"
  },
  {
    name: "Quora",
    icon: quoraIcon,
    link: "#"
  },
  {
    name: "Medium",
    icon: mediumIcon,
    link: "#"
  }
];

export default linkIconDetails;
