import "./styles.scss";
import linkIcons from "./linkIcons";
import FooterLinkIcon from "./FooterLinkIcon";
import logo from "../../assets/Footer/Group.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <img src={logo} alt="logo" className="logo" />
      <h1>Join Our Community</h1>
      <a href="_" className="subscribe">
        Subscribe
      </a>
      <p className="text">
        Subscribe to our Community Channels to get regular updates, our group
        developments and free Airdrops & Bounties of Cryptocurrencies.
      </p>
      <ul>
        <li>
          <a href="_">Investor Brochure</a>
        </li>
        <li>
          <a href="_">About Us</a>
        </li>
        <li>
          <a href="_">Join Waitlist</a>
        </li>
        <li>
          <a href="_">Download App</a>
        </li>
      </ul>
      <div className="footer-container__socials">
        <div className="footer-container__socials__set">
          {linkIcons.map((linkIcon) => {
            return <FooterLinkIcon key={Math.random()} linkIcon={linkIcon} />;
          })}
        </div>
      </div>
      <div className="bottom-links">
        <a href="_">Privacy policy</a>
        <a href="_">Terms of use</a>
      </div>
      <p className="copyright">© 2022 SLAPA X . All rights reserved.</p>
    </div>
  );
};

export default Footer;
