import "./styles.scss";

const HeadingSection = (props: { text: string }) => {
  const { text } = props;
  return (
    <div className="heading">
      <div className="bar"></div>

      <h1 className="heading-text">{text}</h1>
    </div>
  );
};

export default HeadingSection;
