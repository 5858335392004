import { useState } from "react";
import arrow from "../../assets/WaitingList/arrow.svg";
import rightArrowIcon from "../../assets/WaitingListIcons/Arrow - Right.svg";
import linkIconDetails from "./linkIcons";

import "./styles.scss";
import WaitingListLinkIcon from "./WaitingListIcon/index";

const WaitingList = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const onchagePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const formSubmit = (e: any) => {
    e.preventDefault();
    setPhoneNumber("");
  };

  return (
    <div className="waitinglist-container" id="waitlist">
      <div className="join">
        <img className="arrow" src={arrow} alt="join the waitlist" />
        <div className="body-texts">
          <p className="text">Join the waitlist</p>
          <p className="text">Refer and move up the board</p>
          <p className="text">Confirm your sport</p>
        </div>
      </div>
      <div className="form-con">
        <div className="waitinglist-container__form">
          <div className="form-heading">
            <h1>Join our Waiting List</h1>
            <div className="heading-text">
              We have over 1 million in our various global waiting list
            </div>
          </div>
          <form onSubmit={formSubmit}>
            <div className="socials">
              {linkIconDetails.map((linkIcon) => {
                return (
                  <WaitingListLinkIcon
                    key={Math.random()}
                    linkIcon={linkIcon}
                  />
                );
              })}
            </div>
            <input
              type="number"
              placeholder="Enter phone number"
              onChange={onchagePhoneNumber}
              value={phoneNumber}
            />

            <div className="form-submit" onClick={formSubmit}>
              Send <img src={rightArrowIcon} alt="" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
