import { useState } from "react";
import logo from "../../assets/Navbar/Group.png";
import "./styles.scss";

const NavBar = () => {
  const [menuShow, setMenuShow] = useState(false);

  const toggleMenu = () => {
    setMenuShow(!menuShow);
  };

  return (
    <div className="navBar">
      <img src={logo} alt="logo" className="logo" />

      <div className={`menuItems-mobile ${menuShow && "show"}`}>
        <ul>
          <li onClick={toggleMenu}>
            <a href="#about-us"> About Us</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#overview">Overview</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#features">Features</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#how-it-works">How it works</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#faqs">FAQs</a>
          </li>
        </ul>
        <div className="subscribe">
          <a href="_">Subscribe</a>
        </div>
      </div>

      <div className="menuItems">
        <ul>
          <li>
            <a href="#about-us"> About Us</a>
          </li>
          <li>
            <a href="#overview">Overview</a>
          </li>
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a href="#how-it-works">How it works</a>
          </li>
          <li>
            <a href="#faqs">FAQs</a>
          </li>
        </ul>
        <div className="subscribe">
          <a href="_">Subscribe</a>
        </div>
      </div>

      <div
        className={`hamburger-container ${menuShow ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="hamburger"></div>
      </div>
    </div>
  );
};

export default NavBar;
