import image from "../../assets/HowItWorks/image.png";
import HeadingSection from "../HeadingSection";
import "./styles.scss";

const HowItWorks = () => {
  return (
    <div className="howItWorks" id="how-it-works">
      <HeadingSection text="How It Works" />
      <main>
        <div className="image">
          <img src={image} alt="How it works" />
        </div>
        <div className="text">
          SLAPA X is deposited in SACA as collateral and Synthetic SLAPA Token
          [s$SLAPA, also known as s$Ts] is minted against it. Furthermore,
          additional SLAPA X Coins are credited into the SACA by DASA as
          leverage multiples (if applied for by User)and they become
          collateralized debt positions (CDPs) secured against the SACA and it’s
          eponymous contracts / agreements.
        </div>
      </main>
    </div>
  );
};

export default HowItWorks;
