import telegramIcon from "../../assets/WaitingListIcons/Telegram.svg";
import whatsappIcon from "../../assets/WaitingListIcons/Whats app.svg";
import viberIcon from "../../assets/WaitingListIcons/Viber.svg";
import signalIcon from "../../assets/WaitingListIcons/Signal.svg";
import discordIcon from "../../assets/WaitingListIcons/discord.svg";
import lineIcon from "../../assets/WaitingListIcons/line.svg";
import twitterIcon from "../../assets/WaitingListIcons/Twitter.svg";

const linkIconDetails = [
  {
    name: "Telegram",
    icon: telegramIcon,
    link: null
  },
  {
    name: "Whatsapp",
    icon: whatsappIcon,
    link: null
  },
  {
    name: "Viber",
    icon: viberIcon,
    link: null
  },
  {
    name: "Signal",
    icon: signalIcon,
    link: null
  },
  {
    name: "Discord",
    icon: discordIcon,
    link: null
  },

  {
    name: "Line",
    icon: lineIcon,
    link: null
  },
  {
    name: "Twitter",
    icon: twitterIcon,
    link: null
  }
];

export default linkIconDetails;
