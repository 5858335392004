import HeadingSection from "../HeadingSection";
import "./styles.scss";
import Faq1 from "./Faq1";
import Faq2 from "./Faq2/index";

const Faqs = () => {
  return (
    <div className="faq" id="faqs">
      <HeadingSection text="FAQs" />
      <div className="sections">
        <div>
          <Faq1 />
        </div>
        <div>
          <Faq2 />
        </div>
      </div>
    </div>
  );
};

export default Faqs;
