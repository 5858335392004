import "./styles.scss";
import android from "../../assets/MobileApp/android.png";
import ios from "../../assets/MobileApp/ios.png";

const MobileApp = () => {
  return (
    <div className="mobile-app">
      <main>
        <h1>Download our mobile application</h1>
        <p className="text">
          Sign up for the Waiting List and be rewarded* with $10.and also get
          referral payments for each referral that you make, unlimited times
        </p>

        <div className="buttons">
          <a href="_" className="button">
            <img src={android} alt="android" />
          </a>
          <a href="_" className="button">
            <img src={ios} alt="ios" />
          </a>
        </div>
      </main>
    </div>
  );
};

export default MobileApp;
